import React, { Fragment, useEffect, useState } from "react";
import { Loading } from "../../../models/loading2";
import { Route } from "../../../routing/app-routes";
import { renderLoading } from "../../../ui/widgets/loader/loader2";
import { SeasonDetails } from "../../../adl-gen/myc/scoring/api";

// const styles = require("./main-page.css");

  
export interface MainProps {
  loadSeasons(): Promise<SeasonDetails[]>;
  onNavigate(to: Route): void;
}
  
/**
 * The Main page.
 */
export function MainPage(props: MainProps) {
  const [seasons, setSeasons] = useState<Loading<SeasonDetails[]>>({kind:"loading"});

  useEffect(() => {
    async function load() {
      setSeasons({kind:"loading"});
      const loadedSeasons = await props.loadSeasons();
      setSeasons({kind:"value", value: loadedSeasons});
    }
    void load()
  }, []);

  const renderSeasons =  (loadedSeasons: SeasonDetails[]) => 
    <Fragment>
    {loadedSeasons.map( s =>
    <Fragment>
    <h2>{s.season.value.name}</h2>
    <ol>
    <li key={s.season.id}>
      <a onClick={() => props.onNavigate({route:'schedule',season : s.season.id})}>
        Race Schedule
      </a>
    </li>
    {s.series.map(series => <li key={series.id}>
      <a onClick={() => props.onNavigate({route:'series-results', series : series.id})}>
        {series.value.name} ({series.value.abbreviation})
      </a>
    </li>)
    }
    </ol>
    </Fragment>)}
    </Fragment>;

  return (
    <div className="page-layout">
        <div className="container">
            <h1>Race results</h1>
            {renderLoading(seasons, renderSeasons)}
        </div>
    </div>
  );
}
