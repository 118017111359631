import { cellContent, Column } from "@adltools/adl-table";
import { AdlTable } from "@adltools/components/AdlTable";
import React, { useEffect, useState } from "react";

import { RaceScheduleEntry, RaceScheduleResp } from "../../../adl-gen/myc/scoring/api";
import { CalendarEntry, SeasonId, Event } from "../../../adl-gen/myc/scoring/db";
import { Loading } from "../../../models/loading2";
import { Route } from "../../../routing/app-routes";
import { renderLoading } from "../../../ui/widgets/loader/loader2";
import { WithDbId } from "../../../adl-gen/common/db";
import { RouteLink } from "../../../ui/widgets/routelink";


// const styles = require("./schedule-page.css");

export interface ScheduleProps {
  season: SeasonId;
  loadSchedule(season: SeasonId): Promise<RaceScheduleResp>;
  showResultsLink: boolean;
  onNavigate(to: Route): void;
}

type ScheduleItem = {kind:"race", value: RaceScheduleEntry} | {kind: "calendar", value: CalendarEntry}
  
/**
 * The Schedule page.
 */
export const SchedulePage = (props:ScheduleProps) => {

  const [schedule, setSchedule] = useState<Loading<RaceScheduleResp>>({kind:"loading"});

  useEffect(() => {
    async function load() {
      setSchedule({kind:"loading"});
      const loaded = await props.loadSchedule(props.season);
      setSchedule({kind:"value", value: loaded});
    }
    void load()
  }, [props.season]);

  // tslint:disable-next-line:no-any
  const AdlTableT: new() => AdlTable<ScheduleItem, string> = AdlTable as any;
  const columns : Column<ScheduleItem,string>[] = [
     { 
         id: "date",
         header: cellContent("Date"),
         content: (item: ScheduleItem, _i: number) => cellContent(item.value.date)
     },
     { 
         id: "n",
         header: cellContent("Race"),
         content: (item: ScheduleItem, _i: number) => 
           item.kind === 'race' ? cellContent(item.value.raceNumber.toString()) : null,
     },
     { 
         id: "description",
         header: cellContent("Description"),
         content: (item: ScheduleItem, _i: number) => 
         item.kind === 'race'
          ? cellContent(renderEvents(item.value.events))
          : cellContent(item.value.description),
     },
     { 
      id: "dutyoffice",
      header: cellContent("Duty"),
      content: (item: ScheduleItem, _i: number) => 
      item.kind === 'race' && item.value.raceNumber === 1 ? cellContent(item.value.dutyOfficer) : null,
    },
  ];

  columns.push({ 
    id: "signon",
    header: null,
    content: (item: ScheduleItem, _i: number) => 
    item.kind === 'race' && item.value.raceNumber === 1 ? cellContent(
    <RouteLink 
      text="Signon"
      route={{route:'race-signon', race:item.value.raceId}}
      onNavigate={props.onNavigate}
    />) : null,
  });

  if (props.showResultsLink) {
    columns.push({ 
      id: "results",
      header: null,
      content: (item: ScheduleItem, _i: number) => 
      item.kind === 'race' ? cellContent(
      <RouteLink 
        text="Results"
        route={{route:'race-results', race:item.value.raceId}}
        onNavigate={props.onNavigate}
      />) : null,
    });
  }

  const renderEvents = (events: WithDbId<Event>[]) => {
    const items: JSX.Element[] = [];
    events.forEach(ev => {
      if (items.length > 0) {
        items.push(<span>,</span>)
      }
      items.push(<RouteLink 
        text={ev.value.abbreviation}
        route={{route:'event-results', event:ev.id}}
        onNavigate={props.onNavigate}
      />);
    })
    return <span>{items}</span>;
  }

  const renderLoaded = (loaded: RaceScheduleResp) => {
    let scheduleItems: ScheduleItem[] = [];
    scheduleItems = scheduleItems.concat(loaded.races.map(value => {return {kind:"race", value}}));
    scheduleItems = scheduleItems.concat(loaded.calendarEntries.map(value => {return {kind:"calendar", value}}));
    scheduleItems.sort(orderScheduleItems);

    const cdate = (new Date()).toISOString().substring(0, 10);;
    const nextItems = scheduleItems.filter(si => si.value.date >= cdate);
    const previousItems = scheduleItems.filter(si => si.value.date < cdate);

    return (
      <div className="page-layout">
        <div className="container">
           <h1>{loaded.season.name} Race schedule</h1>
           <h2>Next</h2>
            <AdlTableT
              columns = {columns}
              values = {nextItems}
            />
            <h2>Previous</h2>
            <AdlTableT
              columns = {columns}
              values = {previousItems}
            />
        </div>
     </div>
     );
  }

  return renderLoading(schedule, renderLoaded);
}

function orderScheduleItems(s1: ScheduleItem, s2: ScheduleItem): number {
  const c1 = s1.value.date.localeCompare(s2.value.date);
  if (c1 !== 0) {
    return c1;
  }

  const r1 = s1.kind === 'race' ? s1.value.raceNumber : 0;
  const r2 = s2.kind === 'race' ? s2.value.raceNumber : 0;

  return r1 > r2 ? 1 : r2 > r1 ? -1 : 0;
} 
