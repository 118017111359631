import { assertNever } from "@hx/util/types";
import { observer } from "mobx-react";
import * as React from "react";

import { UserProfile } from "../adl-gen/myc/scoring/api";
import { UiConfig } from "../adl-gen/myc/scoring/uiconfig";
import { RESOLVER } from "../adl-gen/resolver";
import { AdminUiMainPage } from "../adminui/page/main-page";
import { AdminUiTablePage } from "../adminui/page/table-page";
import { AdminUiValuePage } from "../adminui/page/value-page";
import { ADMIN_HREF_FACTORY, pathFromRoute} from "../routing/app-routes";
import { AppStore } from "../stores/app-store";
import { LinkItem, Menu, PageLayout } from "../ui/layouts/page-layout/page-layout";
import { EventResultsPage } from "../ui/page/event-results/event-results-page";
import { LoginPage } from "../ui/page/login-page/login-page";
import { LogoutPage } from "../ui/page/logout-page/logout-page";
import { MainPage } from "../ui/page/main/main-page";
import { RaceResultsEditorPage } from "../ui/page/race-results-editor/race-results-editor-page";
import { RaceSignonPage } from "../ui/page/race-signon/race-signon-page";

import { SchedulePage } from "../ui/page/schedule/schedule-page";
import { SeriesResultsPage } from "../ui/page/series-results/series-results-page";

interface AppProps {
  /** Top level app state and actions */
  store: AppStore;
  /** UI configuration */
  uiconfig: UiConfig;
}

@observer
export class App extends React.Component<AppProps> {
  render() {
    const state = this.props.store.state;

    if (state.kind === "login") {
      return (
        <PageLayout title="Login" menu={createMenu(this.props.store, undefined)}>
          <LoginPage
            state={{
              loginError: this.props.store.identityStore.loginError()
            }}
            onEvent={(ev) => { return this.props.store.onLogin(ev.req); }}
          />
        </PageLayout>
      );

    } else if (state.kind === "logout") {
      return (
        <PageLayout
          title="Logout"
          menu={createMenu(this.props.store, undefined)}
        >
          <LogoutPage message={"You have been logged out"} />
        </PageLayout>
      );
    } else if (state.kind === "public") {
      if (state.route.route === 'main') {
        return (
          <PageLayout
            title="Home"
            menu={createMenu(this.props.store, state.userProfile)}
          >
            <MainPage
              loadSeasons={this.props.store.service.seasons}
              onNavigate={this.props.store.navigateTo}
               />
          </PageLayout>
        );
      } else if (state.route.route === 'schedule') {
        return (
          <PageLayout
            title="Schedule"
            menu={createMenu(this.props.store, state.userProfile)}
          >
            <SchedulePage
              season={state.route.season}
              loadSchedule={this.props.store.service.raceSchedule}
              onNavigate={this.props.store.navigateTo}
              showResultsLink={state.userProfile != undefined}
               />
          </PageLayout>
        );
      } else if (state.route.route === 'race-signon') {
        return (
          <PageLayout
            title="Race Signon"
            menu={createMenu(this.props.store, state.userProfile)}
          >
            <RaceSignonPage 
              raceId={state.route.race}
              getRaceEntrants={this.props.store.service.getRaceEntrants}
              getRaceResults={this.props.store.service.getRaceResults}
            />
          </PageLayout>
        );
      } else if (state.route.route === 'series-results') {
        return (
          <PageLayout
            title="Race Results"
            menu={createMenu(this.props.store, state.userProfile)}
          >
            <SeriesResultsPage
              loadSeriesResults={this.props.store.service.getSeriesResults}
              seriesId={state.route.series}
              onNavigate={this.props.store.navigateTo}
            />
          </PageLayout>
        );
      } else if (state.route.route === 'event-results') {
        return (
          <PageLayout
            title="Race Results"
            menu={createMenu(this.props.store, state.userProfile)}
          >
            <EventResultsPage 
              eventId={state.route.event}
              getEventResults={this.props.store.service.getEventResults}
            />
          </PageLayout>
        );
      } else {
        return <div>No public page for route ${pathFromRoute(state.route)}</div>;
      }
    } else if (state.kind === "secure") {
      if (state.route.route === 'admin-value') {
        return (
          <PageLayout
            title="Admin"
            menu={createMenu(this.props.store, state.userProfile)}
          >
            <AdminUiValuePage
              service={this.props.store.service}
              appDeclResolver={RESOLVER}
              hrefFactory={ADMIN_HREF_FACTORY}
              table={state.route.table}
              id={state.route.id}
              onDone={() => this.props.store.navigateTo({ route: "admin" })}
            />
          </PageLayout>
        );
      } else if (state.route.route === 'race-results') {
        return (
          <PageLayout
            title="Race Results"
            menu={createMenu(this.props.store, state.userProfile)}
          >
            <RaceResultsEditorPage 
              raceId={state.route.race}
              getRaceEntrants={this.props.store.service.getRaceEntrants}
              getRaceResults={this.props.store.service.getRaceResults}
              updateRaceResults={this.props.store.service.updateRaceResults}
            />
          </PageLayout>
        );
      } else {
        return <div>No secure page for route ${pathFromRoute(state.route)}</div>;
      }
    } else if (state.kind === "admin") {
      return (
        <PageLayout
          title="Admin"
          menu={createMenu(this.props.store, state.userProfile)}
        >
          <AdminUiMainPage
            state={{
             tables: state.store.getTables(),
             actions: state.store.getActions(),
            }}
            onEvent={ (ev) => {
              switch (ev.kind) {
                case 'click-table':
                  void this.props.store.navigateTo({ route: "admin-table", table:ev.table });
                  break;
                case 'done':
                  this.props.store.onLogout()
              }
            }}
          />
        </PageLayout>
      );

    } else if (state.kind === "admin-table") {
      return (
        <PageLayout
          title="Admin"
          menu={createMenu(this.props.store, state.userProfile)}
        >
          <AdminUiTablePage
            state={state.store.getTablePageState()}
            onEvent={ (ev) => {
              switch (ev.kind) {
                case 'done':
                  void this.props.store.navigateTo({route: "admin"});
                  break;
                case 'goto-value':
                  void this.props.store.navigateTo({route: "admin-value", table: ev.table, id: ev.id});
                  break;
                default:
                  state.store.onTablePageEvent(ev)
              }
            }}
            key={state.table}
          />
        </PageLayout>
      );

    } else {
      return assertNever(state, "unknown state");
    }
  }
}

function createMenu(store: AppStore, userProfile: UserProfile | undefined): Menu {
  // tslint:disable-next-line: no-console
  console.log("UserProfile", userProfile);
  const items : LinkItem[] = [];
  items.push({ text: "Home", onClick: () => store.navigateTo({route: "main"})});
  if (userProfile) {
    if (userProfile.isAdmin) {
      items.push({ text: "Admin", onClick: () => store.navigateTo({route: "admin"})});
    }
    items.push({ text: "Logout", onClick: store.onLogout});
  } else {
    items.push({ text: "Login", onClick: () => store.navigateTo({route:"login"})});

  }

  return {
    onClickHome: () => store.navigateTo({route:'main'}),
    title: userProfile ? userProfile.fullname : "",
    items
  }
};
