import { assertNever } from "@hx/util/types";
import { observable, runInAction } from "mobx";

import {
  LoginReq,
  UserProfile
} from "../adl-gen/myc/scoring/api";
import { Service } from "../service/service";
import { TokenManager } from "../service/token-manager";

type LoginState =
  | { kind: "not-logged-in" }
  | { kind: "logged-in" }
  | { kind: "login-failed"; error: string };

/**
 * Data and actions related to user identity.
 */
export class IdentityStore {
  @observable.ref state: LoginState = { kind: "not-logged-in" };
  @observable.ref userProfile: UserProfile | undefined = undefined;
  userProfilePromise: Promise<UserProfile | undefined>;

  constructor(
    /** Token manager to store logged in access token */
    private readonly accessTokenManager: TokenManager,
    /** Service to interact with the server */
    private readonly service: Pick<Service, "login" | "whoami">
  ) {
    this.userProfilePromise =
      accessTokenManager.getToken() === ""
        ? new Promise(resolve => resolve(undefined))
        : this.loadProfile();
  }

  /** Login user and saves access token and user profile. Returns true if successful. */
  onLogin = async (req: LoginReq): Promise<LoginState> => {
    const resp = await this.service.login(req);
    if (resp.kind === "accessToken") {
      this.accessTokenManager.setToken(resp.value);
      this.userProfilePromise = this.loadProfile();
      await this.userProfilePromise;
    } else if (resp.kind === "invalidCredentials") {
      runInAction(() => {
        const error = "Sorry, the email and password is incorrect";
        this.state = { kind: "login-failed", error };
      });
    } else {
      assertNever(resp);
    }
    return this.state;
  };

  /** Handles user logout action */
  onLogout = () => {
    this.accessTokenManager.setToken("");
    runInAction(() => {
      this.state = { kind: "not-logged-in" };
    });
  };

  async loadProfile(): Promise<UserProfile | undefined> {
    const profile = await this.service.whoami();
    runInAction(() => {
      this.state = {kind: "logged-in"};
      this.userProfile = profile;
    });
    return profile;
  }

  isLoggedIn = (): boolean => {
    return this.state.kind === "logged-in";
  };

  loginError = (): string | undefined => {
    if (this.state.kind === "login-failed") {
      return this.state.error;
    }
    return undefined;
  };
}
