import React, {  useEffect, useState, Fragment } from "react";

import { SeriesId, EntrantId, EventId } from "../../../adl-gen/myc/scoring/db";
import * as API from "../../../adl-gen/myc/scoring/api";
import { Loading } from "../../../models/loading2";
import { renderLoading } from "../../../ui/widgets/loader/loader2";
import { stdTable } from "../../../ui/common/table/table";
import { Route } from "../../../routing/app-routes";
import { VMap } from "../../../utils/common-utils";
import { RouteLink } from "../../../ui/widgets/routelink";

const styles = require("./series-results-page.css");

/** Props for the component */
export interface SeriesResultsProps {
  loadSeriesResults(seriesId: SeriesId): Promise<API.GetSeriesResultsResp>;
  seriesId: SeriesId,
  onNavigate(to: Route): void;
}

/**
 * The Series Results page.
 */
export const SeriesResultsPage = (props:SeriesResultsProps) => {

  const [seriesResults, setSeriesResults] = useState<Loading<API.GetSeriesResultsResp>>({kind:"loading"});

  useEffect(() => {
    async function load() {
      setSeriesResults({kind:"loading"});
      const loadedSeasons = await props.loadSeriesResults(props.seriesId);
      setSeriesResults({kind:"value", value: loadedSeasons});
    }
    void load()
  }, [props.seriesId]);

  const renderResults =  (results: API.GetSeriesResultsResp) => {
    const header: JSX.Element[] = [];
    header.push(<Fragment>Entrant</Fragment>);
    header.push(<Fragment>Boat</Fragment>);
    header.push(<Fragment>Sail Number</Fragment>);
    results.events.forEach(event => 
      header.push(
        <RouteLink
          text={event.value.abbreviation}
          route={{route:'event-results', event:event.id}}
          onNavigate={props.onNavigate}
        />
      )
    );
    header.push(<Fragment>Total</Fragment>);

    const numDrops = results.series.value.numDrops;

    type RKey = {entrantId:EntrantId, eventId: EventId};
    const resultMap: VMap<RKey,API.EntrantSeriesResult> = new VMap(key => key.entrantId + "/" + key.eventId);
    results.results.forEach(r => resultMap.put({entrantId:r.entrantId, eventId:r.eventId}, r));

    const entrantRows = results.entrants.map(entrant => {
      const elrow: JSX.Element[] = [];
      let total = 0;
      elrow.push(<Fragment>{entrant.value.entrantName}</Fragment>);
      elrow.push(<Fragment>{entrant.value.boatName}</Fragment>);
      elrow.push(<Fragment>{entrant.value.sailNumber}</Fragment>);
      const rawScores = results.events.map(event => {
        const r = resultMap.get({entrantId:entrant.id, eventId:event.id});
        return r === undefined ? undefined : r.score;
      });
      const scores = calcDrops(numDrops, rawScores);
      scores.forEach(s => {
        if (s.score === -1) {
          elrow.push(<Fragment></Fragment>);
        } else {
          if (s.dropped) {
            elrow.push(<Fragment><span className={styles.droppedscore}>{s.score === 0 ? '-' : Math.round(s.score*100)/100}</span></Fragment>);
          } else {
            elrow.push(<Fragment>{s.score === 0 ? '-' : Math.round(s.score*100)/100}</Fragment>);
            total += s.score;
          }
        }
      });
      elrow.push(<Fragment>{Math.round(total*100)/100}</Fragment>);
      return {elrow,total};
    });

    entrantRows.sort((r1,r2) => r1.total < r2.total ? -1 : r1.total > r2.total ? 1 : 0);

    return <div>
      <h1>{results.season.value.name} {results.series.value.name} Results</h1>
      <div className={styles.resultstablecontainer}>
        {stdTable(header, entrantRows.map(er => er.elrow))}
      </div>
    </div>;
  }
   
  return (
    <div className="page-layout">
        <div className="container">
          {renderLoading(seriesResults, renderResults)}
        </div>
    </div>
    );
};

function calcDrops(numDrops: number, entrantScores: (number|undefined) [] ): Score[] {
  const scores = entrantScores.map( (es,i) => ({score: (es === undefined ? -1 : es), dropped: false, i}) );
  scores.sort((a,b) => b.score - a.score);
  for(let i = 0; i < numDrops; i++) {
    scores[i].dropped = true;
  }
  scores.sort((a,b) => a.i - b.i);
  return scores;
}

interface Score {
  score: number;
  dropped: boolean;
}
