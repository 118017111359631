import { observer } from "mobx-react";
import * as React from "react";

import { MenuIcon } from "../../common/icon/icons";

const styles = require("./page-layout.css");

export interface LinkItem {
  text: string;
  onClick(): void;
}

export interface Menu {
  onClickHome(): void;
  title: string;
  items: LinkItem[];
};

/** Props for the component */
export interface PageLayoutProps {
  /** Page title */
  title: string;

  /** Elements of the primary content area */
  children?: React.ReactNode;

  /** The menu */
  menu: Menu

}

/**
 * Layout for typical pages.
 */
@observer
export class PageLayout extends React.Component<PageLayoutProps> {
  /** Renders the header */
  renderHeader() {
    return (
      <header className={styles.header}>
        <a className={styles.titlelink} onClick={this.props.menu && this.props.menu.onClickHome}>MYC Lasers</a>
        {this.props.menu? (
          <div className={styles.dropdown}>
            <div className={styles.dropbtn}>{this.props.menu.title}
              <span className={styles.menuIcon}><MenuIcon/></span>
            </div>
            <div className={styles.dropcontent}>
              {this.props.menu.items.map((item,i) => <a key={i} onClick={item.onClick}>{item.text}</a>)}
            </div>
          </div> 
        ) : (
          undefined
        )}
      </header>
    );
  }

  /** Renders the content body */
  renderBody() {
    return <div className={styles.body}>{this.props.children}</div>;
  }

  /** Renders the page */
  render() {
    return (
      <div className={styles.page}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  }
}
