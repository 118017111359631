import { FieldFns, localTimeFieldFns, mkLocalDateFieldFns } from "@hx/fields";
import { LocalTime } from "../adl-gen/common";
import { DDSMMSYYYY_FORMAT } from "@hx/dateformats";
import { Result } from "../adl-gen/myc/scoring/db";

export const DATE_FIELD_FNS = mkLocalDateFieldFns(DDSMMSYYYY_FORMAT);

export function resultFieldFns<T>(fieldfns: FieldFns<T>): FieldFns<Result<T>> {
  return {
    width: localTimeFieldFns.width,
    rows: localTimeFieldFns.rows,
    toText: (r) => {
      switch(r.kind) {
        case "dnc": return "dnc";
        case "dnf": return "dnf";
        case "avg": return "avg";
        case "dns": return "dns";
        case "dsq": return "dsq";
        case "finished": return fieldfns.toText(r.value)
      }
    },
    validate: (s) => {
      const s1 = s.toLowerCase();
      if (s1 === 'dnc' || s1 === 'dnf' || s1 ==='dns' || s1 === 'dsq' || s1 == 'avg') {
        return null;
      }
      if (localTimeFieldFns.validate(s) === null) {
        return null;
      }
      return "expected a time in hh:mm:ss format, or a finishing code"
    },
    equals: (r1,r2) => {
      if (r1.kind !== r2.kind) {
        return false;
      }
      if (r1.kind === 'finished' && r2.kind === 'finished') {
        return r1.value === r2.value;
      }
      return true;
    },
    fromText: (s) => {
      switch(s.toLowerCase()) {
        case "dnc": return {kind:"dnc"};
        case "dnf": return {kind:"dnf"};
        case "dns": return {kind:"dns"};
        case "dsq": return {kind:"dsq"};
        case "avg": return {kind:"avg"};
      }
      return {kind:"finished", value: fieldfns.fromText(s)}
    },
    datalist: ["dnc", "dnf", "dns", "dsq", "avg"]
  }
};

const DURATION_REGEXP = new RegExp("^[ \t]*(([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9]([.][0-9]+)?))[ \t]*$");


export const DURATION_FIELDFNS: FieldFns<number> =  {
    width: localTimeFieldFns.width,
    rows: localTimeFieldFns.rows,
    toText: (d) => {
      const seconds = (d % 60);
      const minutes = Math.floor(d / 60) % 60;
      const hours = Math.floor(d / 3600);

      return hours.toString() 
       + ":" + (minutes < 10 ? "0" : "") + minutes.toString()
       + ":" + (seconds < 10 ? "0" : "") + seconds.toFixed(1);
    },
    validate: (text) => {
      const m = text.match(DURATION_REGEXP);
      if (m) {
        const min = parseInt(m[3], 10);
        const sec =parseFloat(m[4]);
        if (min >= 0 && min < 60 && sec >= 0 && sec < 60) {
          return null;
        }
      }
      return "must be a duration in HH:MM:SS format";
    },
    equals: (d1,d2) => {
      return d1 == d2; 

    },
    fromText: (s) => {
      const m = s.match(DURATION_REGEXP);
      if (m) {
        const hour = parseInt(m[2], 10);
        const min = parseInt(m[3], 10);
        const sec =parseFloat(m[4]);
        return hour * 3600 + min * 60 + sec;
      }
      return 0;
    },
    datalist: []
};

export const RESULT_LOCALTIME_FIELDFNS: FieldFns<Result<LocalTime>> = resultFieldFns(localTimeFieldFns);
export const RESULT_DURATION_FIELDFNS: FieldFns<Result<number>> = resultFieldFns(DURATION_FIELDFNS);
