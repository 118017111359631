import { createVEditor, Customize, VEditor } from "@adltools/adl-veditor";
import { assertNever } from "@hx/util/types";
import { action, observable, runInAction } from "mobx";

import { Paginated, Unit } from "../adl-gen/common";
import { DbKeyLabelReq,  DbResult, QueryReq, Table } from "../adl-gen/common/adminui/api";
import { DbKey, WithDbId } from "../adl-gen/common/db";
import * as common_http from "../adl-gen/common/http";
import { makeTableQuery, makeTableView, TableView } from "../adl-gen/common/tabular";
import { DeclResolver } from "../adl-gen/runtime/adl";
import { createJsonBinding, JsonBinding } from "../adl-gen/runtime/json";
import { scopedNamesEqual } from "../adl-gen/runtime/utils";
import * as adlast from "../adl-gen/sys/adlast";

import { AdminService } from "./service";
import { appCustomize, createTableMetadata, dbKeyScopedName, HrefFactory, Loading, loadMetadata, mapDbValue, mapLoading, Metadata, TableMetadata, TSRow } from "./utils";


export interface TablePageState {
  table: string;
  tmetadata: Loading<TableMetadata>;
  loadedRows: Paginated<WithDbId<TSRow>> | null;
  canPageBack: boolean;
  canPageForward: boolean;
  rowsLoading: boolean;
  tableView: TableView | null;
  pendingDbError: string | null;
  getDbKeyTable(scopedName: adlast.ScopedName): string;
  getDbKeyLabel(scopedName: adlast.ScopedName, id: string): string;
};

export type TablePageEvent
  = {kind: 'pageback' }
  | {kind: 'pageforward' }
  | {kind: 'reload' }
  | {kind: 'cleardberror' }
  | {kind: 'settableview', view: TableView }
  | {kind: 'update', tmetadata: TableMetadata, value: WithDbId<TSRow>}
  | {kind: 'create', tmetadata: TableMetadata, value: TSRow}
  | {kind: 'delete', tmetadata: TableMetadata, id: DbKey<TSRow>}
  ;


export class AdminStore {
  @observable.deep
  metadata: Loading<Metadata>;

  @observable
  tableName: string | null;

  @observable.deep
  lastQuery: {
    tmetadata: TableMetadata;
    view: TableView;
    query: QueryReq;
  } | null;

  @observable.deep
  loadedRows: Paginated<WithDbId<TSRow>> | null;

  @observable
  rowsLoading: boolean;

  @observable
  pendingDbError: string | null = null;

  actions: ActionData[] = [];

  pageSize: number = 20;

  dbKeyCache : DbKeyCache;

  constructor(
    readonly service: AdminService,
    readonly tableHrefFn: HrefFactory,
    readonly actionFactories: ActionFactory[],
    private readonly appDeclResolver: DeclResolver
  ) {
    this.metadata = { kind: "loading" };
    this.tableName = null;
    this.lastQuery = null;
    this.loadedRows = null;
    this.dbKeyCache = new DbKeyCache(service);
    void this.reloadMetadata();
  }

  @action
  reloadMetadata = async () => {
    const metadata: Metadata = await loadMetadata(this.service, this.appDeclResolver);
    this.metadata = { kind: "ready", value: metadata };
    if (this.tableName !== null) {
      await this.initialLoad(metadata, this.tableName);
    }
  };

  @action
  setTable = async (tableName: string): Promise<void> => {
    this.tableName = tableName;
    if (this.metadata.kind === "ready") {
      await this.initialLoad(this.metadata.value, tableName);
    }
  };

  initialLoad = async (metadata: Metadata, tableName: string): Promise<void> => {
    const tmetadata = createTableMetadata(metadata, tableName, this.tableHrefFn);
    this.lastQuery = {
      tmetadata,
      view: makeTableView({
        columns: []
      }),
      query: {
        table: tmetadata.table.name,
        columns: [],
        query: makeTableQuery({
          offset: 0,
          count: this.pageSize
        })
      }
    };
    await this.loadRows();
  };

  @action
  loadRows = async (): Promise<void> => {
    if (this.lastQuery !== null) {
      const tmetadata = this.lastQuery.tmetadata;
      this.rowsLoading = true;
      this.lastQuery.query.query.filter = this.lastQuery.view.filter;
      this.lastQuery.query.query.sorting = this.lastQuery.view.sorting;
      const dbrows = await this.service.adminQuery(this.lastQuery.query);
      const tsrows = dbrows.items.map(dbrow => mapDbValue(tmetadata.tsRowFromDbRow, dbrow));
      await this.cacheDbKeyLabels(tmetadata, tsrows);
      this.loadedRows = {
        items: tsrows,
        current_offset: dbrows.current_offset,
        total_size: dbrows.total_size
      };
      this.rowsLoading = false;
    }
  };

  /**
   * Save db key labels for references from the given rows.
   */
  cacheDbKeyLabels = async (tmetadata: TableMetadata, tsrows: WithDbId<TSRow>[] ) => {

    // First find the keys we need
    for (const tsrow of tsrows) {
      for(const tcol of tmetadata.table.columns) {
        const dbKeyType = dbKeyScopedName(tcol.typeExpr);
        if (dbKeyType) {
          this.dbKeyCache.requestLabel(dbKeyType, tsrow.value[tcol.name] as string);
        }
      }
    }

    // Fetch them in a batch
    await this.dbKeyCache.fetch();

  }

  @action
  setView = async (view: TableView): Promise<void> => {
    if (this.lastQuery !== null) {
      this.lastQuery.view = view;
      await this.loadRows();
    }
    return;
  };

  // @computed
  getTableView = (): TableView | null => {
    return this.lastQuery && this.lastQuery.view;
  };

  @action
  create = async (tmetadata: TableMetadata, tsrow: TSRow): Promise<void> => {
    const dbresult = await this.service.adminCreate({
      table: tmetadata.table.name,
      values: tmetadata.dbRowFromTsRow(tsrow)
    });
    await this.updateAfterDbChange(dbresult);
  };

  @action
  update = async (tmetadata: TableMetadata, tsrow: WithDbId<TSRow>): Promise<void> => {
    const dbresult = await this.service.adminUpdate({
      table: tmetadata.table.name,
      values: mapDbValue(tmetadata.dbRowFromTsRow, tsrow)
    });
    await this.updateAfterDbChange(dbresult);
  };

  @action
  delete = async (tmetadata: TableMetadata, id: DbKey<TSRow>): Promise<void> => {
    const dbresult = await this.service.adminDelete({
      table: tmetadata.table.name,
      id
    });
    await this.updateAfterDbChange(dbresult);
  };

  updateAfterDbChange = async (dbresult: DbResult<Unit>): Promise<void> => {
    if (dbresult.kind === "ok") {
      await this.loadRows();
    } else {
      runInAction(() => {
        this.pendingDbError = dbresult.value;
      });
    }
  }

  //  @computed
  getLoadedRows = (): WithDbId<TSRow>[] => {
    if (this.loadedRows) {
      return this.loadedRows.items;
    }
    return [];
  };

  //  @computed
  getTables = (): Loading<Table[]> => {
    return mapLoading(m => {
      const tables: Table[] = [];
      m.tableNames.forEach(tn => {
        const table = m.tableMap[tn];
        if (table !== undefined) {
          tables.push(table);
        }
      });
      return tables;
    }, this.metadata);
  };

  //  @computed
  canPageBack = (): boolean => {
    return !this.rowsLoading && this.loadedRows !== null && this.loadedRows.current_offset > 0;
  };

  @action
  pageBack = async (): Promise<void> => {
    if (this.canPageBack() && this.lastQuery !== null) {
      this.lastQuery.query.query.offset -= this.pageSize;
      if (this.lastQuery.query.query.offset < 0) {
        this.lastQuery.query.query.offset = 0;
      }
    }
    await this.loadRows();
  };

  //  @computed
  canPageForward = (): boolean => {
    return (
      !this.rowsLoading &&
      this.loadedRows !== null &&
      this.loadedRows.current_offset + this.loadedRows.items.length < this.loadedRows.total_size
    );
  };

  @action
  pageForward = async (): Promise<void> => {
    if (this.canPageForward() && this.lastQuery !== null) {
      this.lastQuery.query.query.offset += this.pageSize;
    }
    await this.loadRows();
  };

  getActions() {
    if (this.actions.length === 0 && this.metadata.kind === "ready") {
      const ctx: ActionContext = {
        resolver: this.appDeclResolver,
        customize: appCustomize(this.metadata.value, this.tableHrefFn)
      };
      this.actions = this.actionFactories.map(af => {
        return af(ctx);
      });
    }
    return this.actions;
  }

  getDbKeyTable = (sn: adlast.ScopedName): string => {
    if (this.metadata.kind === 'ready') {
      for(const tname of this.metadata.value.tableNames) {
        const table = this.metadata.value.tableMap[tname];
        if (table) {
          const tsn =  {moduleName:table.declModuleName, name:table.declName};
          if (table && scopedNamesEqual(sn,tsn)) {
           return tname;
         }
        }
      }
      throw new Error("No db table found for " + sn.name);
    } else {
      throw new Error("metadata not loaded");
    }
  };

  getTablePageState(): TablePageState {
    return {
      table: this.tableName || "",
      tmetadata: this.lastQuery? {kind:"ready", value:this.lastQuery.tmetadata} : {kind:"loading"},
      tableView: this.lastQuery? this.lastQuery.view: null,
      loadedRows: this.loadedRows,
      rowsLoading: this.rowsLoading,
      canPageBack: this.canPageBack(),
      canPageForward: this.canPageForward(),
      pendingDbError: this.pendingDbError,
      getDbKeyTable: this.getDbKeyTable,
      getDbKeyLabel: (sn, id) => this.dbKeyCache.getLabel(sn, id),
    };
  }

  @action
  onTablePageEvent(ev: TablePageEvent) {
    switch (ev.kind) {
      case 'pageback': void this.pageBack(); break;
      case 'pageforward': void this.pageForward(); break;
      case 'reload': void this.loadRows(); break;
      case 'cleardberror': this.pendingDbError = null; break;
      case 'settableview': void this.setView(ev.view); break;
      case 'create': void this.create(ev.tmetadata, ev.value); break;
      case 'update': void this.update(ev.tmetadata, ev.value); break;
      case 'delete': void this.delete(ev.tmetadata, ev.id); break;
      default:
        assertNever(ev, "unhandled event type");
    }
  }
}

export interface TActionData<I, O> {
  method: string;
  path: string;
  description: string;
  veditorReq: VEditor<I, unknown, unknown>;
  jsonBindingReq: JsonBinding<I>;
  call(req: I): Promise<O>;
  veditorResp: VEditor<O, unknown, unknown>;
  jsonBindingResp: JsonBinding<O>;
}

export type ActionData = TActionData<unknown, unknown>;

export interface ActionContext {
  resolver: DeclResolver;
  customize: Customize;
}

export type ActionFactory = (ctx: ActionContext) => ActionData;

interface PostFn<I, O> {
  description(): string;
  rtype: common_http.HttpPost<I, O>;
  call(req: I): Promise<O>;
}

export function makePostAction<I, O>(details: PostFn<I, O>): ActionFactory {
  return (ctx: ActionContext) => {
    const veditorReq: VEditor<I, unknown, unknown> = createVEditor(
      details.rtype.reqType,
      ctx.resolver,
      ctx.customize
    );
    const jsonBindingReq = createJsonBinding(ctx.resolver, details.rtype.reqType);
    const veditorResp: VEditor<O, unknown, unknown> = createVEditor(
      details.rtype.respType,
      ctx.resolver,
      ctx.customize
    );
    const jsonBindingResp = createJsonBinding(ctx.resolver, details.rtype.respType);

    return {
      method: "post",
      path: details.rtype.path,
      description: details.description(),
      veditorReq,
      jsonBindingReq,
      veditorResp,
      jsonBindingResp,
      call: details.call
    };
  };
}

class DbKeyCache {

  cache : {[key:string]: string | undefined} = {};
  pending: {[key:string]: DbKeyLabelReq} = {};

  constructor(readonly service: AdminService) {
  }

  requestLabel(scopedName: adlast.ScopedName, id: string): void {
    const key = this.keyStr(scopedName, id);
    const label = this.cache[key];
    if (label === undefined) {
      this.pending[key] = {
        scopedName,
        id
      };
    }
  }

  async fetch() {
    const reqs: DbKeyLabelReq[] = [];
    for(const key of Object.keys(this.pending)) {
      reqs.push(this.pending[key]);
    }
    const resps = await this.service.adminDbKeyLabels(reqs);
    // tslint:disable-next-line:prefer-for-of
    for(let i = 0; i < resps.length; i++) {
      this.cache[this.keyStr(resps[i].scopedName, resps[i].id)] = resps[i].label;
    }
    this.pending = {};
  }

  getLabel(scopedName: adlast.ScopedName, id: string): string {
    const label = this.cache[this.keyStr(scopedName, id)];
    return label || "";
  }

  keyStr(scopedName: adlast.ScopedName, id: string): string {
    return scopedName.moduleName + "|" + scopedName.name + "|" + id;
  }
 }
