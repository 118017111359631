import { AdlEditor } from "@adltools/adl-editor";
import * as AT from "@adltools/adl-table";
import React, { useEffect, useState } from "react";
import { Button,  Header, Modal } from "semantic-ui-react";

import { WithDbId } from "../../adl-gen/common/db";
import { makeTableQuery } from "../../adl-gen/common/tabular";
import { DeclResolver } from "../../adl-gen/runtime/adl";
import { AdminService } from "../service";
import { createTableMetadata, HrefFactory, Loading, loadMetadata, mapDbValue, TableMetadata, TSRow } from "../utils";

import { renderLoading } from "./main-page";
import { renderDbError } from "./table-page";

const styles = require("./value-page.css");

/** Props for the component */
export interface AdminUiValuePageProps {
  service: AdminService,
    appDeclResolver: DeclResolver,
    hrefFactory: HrefFactory,
    table: string;
    id: string;
    onDone(): void;
}


interface ValueLoaded {
    tmetadata: TableMetadata;
    value: WithDbId<TSRow>;
    veditorState: unknown;
};

type ValueState = "notfound" | ValueLoaded;
type ModalState = null | EditModal;

interface EditModal {
  kind: "edit";
  tmetadata: TableMetadata;
  value: WithDbId<TSRow>;
};


type StatePair<T> = [T, React.Dispatch<React.SetStateAction<T>>];

interface AdminUiValueState {
  value: StatePair<Loading<ValueState>>;
  dbError: StatePair<string|null>;
  modal: StatePair<ModalState>;
};

/**
 * The Value editor page.
 */
export const AdminUiValuePage = (props:AdminUiValuePageProps) => {
  // Create state
  const value = useState<Loading<ValueState>>({kind:"loading"});
  const dbError = useState<string|null>(null);
  const modal = useState<ModalState>(null);

  // Render the view
  return AdminUiValueView(props, {value, dbError, modal});
}

// Stateless view
export const AdminUiValueView = (props:AdminUiValuePageProps, state: AdminUiValueState ) => {
  const [value,setValue] = state.value;
  const [dbError,setDbError] = state.dbError;
  const [modal,setModal] = state.modal;


  function setPageState(tmetadata: TableMetadata, v: WithDbId<TSRow>) {
    const veditorState = tmetadata.veditor.stateFromValue(v.value);
    const pageState = {
      tmetadata,
      value: v,
      veditorState
    }
    setValue({kind:'ready', value:pageState})
  }

  async function load() {
      const metadata = await loadMetadata(props.service, props.appDeclResolver);
      const tmetadata = createTableMetadata(metadata, props.table, props.hrefFactory);
      const page = await props.service.adminQuery({
          table: tmetadata.table.name,
          columns: tmetadata.table.columns.map(c => c.name),
          query: makeTableQuery({
            filter: {kind:'equals',value:{field:'id', value:props.id}}
          })
      });
      if (page.items.length !== 1) {
        setValue({kind:'ready', value:"notfound"})
      }
      const v = mapDbValue(tmetadata.tsRowFromDbRow, page.items[0]);
      setPageState(tmetadata, v);
  }

  useEffect(() => {
    void load();
  }, [props.table, props.id]);

  function onEdit(tmetadata: TableMetadata, v: WithDbId<TSRow>) {
    setModal({kind:"edit", tmetadata, value:v})
  };

  async function onEditDone(tmetadata: TableMetadata, v: WithDbId<TSRow>): Promise<void> {
    const dbresult = await props.service.adminUpdate({
      table: tmetadata.table.name,
      values: mapDbValue(tmetadata.dbRowFromTsRow, v),
    });
    if (dbresult.kind === "ok") {
      setPageState(tmetadata, v);
    } else {
      setDbError(dbresult.value);
    }
    
    setModal(null);
  }
  
  function onClearModal() {
    setModal(null);
  }

  function renderModal(tmetadata: TableMetadata): JSX.Element | undefined {
    if (modal !== null) {
      const header = "Editing " + tmetadata.table.label + " value with id " + modal.value.id;
      const content = (
        <AdlEditor
          value={modal.value.value}
          veditor={tmetadata.veditor}
          onCancel={onClearModal}
          onApply={(tsrow: TSRow) => {void onEditDone(tmetadata, {id:props.id, value:tsrow})}}
          allowRaw={tmetadata.jsonBinding}
        />
      );
      return (
        <Modal open={true} onClose={onClearModal}>
          <Header>{header}</Header>
          <Modal.Content style={{ margin: 0 }}>{content}</Modal.Content>
        </Modal>
      );
    }
    return undefined;
  }

  function renderPageState(vstate: ValueState): JSX.Element {
      if (vstate === "notfound") {
          return <div>not found</div>;
      } else {
          return (
            <div>
              {renderModal(vstate.tmetadata)}
              {dbError === null ? undefined : renderDbError(dbError, () => setDbError(null))}
              {vstate.tmetadata.veditor.render(vstate.veditorState, false, () => undefined)}
              <div className={styles.buttoncontainer}>
                <Button primary onClick={() => onEdit(vstate.tmetadata, vstate.value)}>Edit</Button>
              </div>
            </div>
          );
      }
  }

  return (
    <div className={styles.page}>
          <div className={styles.topbar1}>  
            <h1>{props.table}</h1>
            <AT.IconButton name="window close outline" onClick={() => props.onDone()} />
          </div>
          <div className={styles.idrow}>id: {props.id}</div>
          <div className={styles.content}>
            {renderLoading(value, renderPageState)}
          </div>
    </div>
    );
};

