import { HttpFetch, HttpRequest } from "@hx/hx/service/http";

import { Paginated, Unit } from "../adl-gen/common";
import * as AR from "../adl-gen/common/adminui/api";
import { MetaAdlDecl } from "../adl-gen/common/adminui/db";
import { DbKey, WithDbId } from "../adl-gen/common/db";
import {
  HttpGet,
  HttpPost,
} from "../adl-gen/common/http";
import { TableQuery } from "../adl-gen/common/tabular";
import * as R from "../adl-gen/myc/scoring/api";
import * as DB from "../adl-gen/myc/scoring/db";
import { ATypeExpr, DeclResolver } from "../adl-gen/runtime/adl";
import {
  createJsonBinding,
  getAnnotation,
  JsonBinding
} from "../adl-gen/runtime/json";
import * as adlast from "../adl-gen/sys/adlast";

import { HttpServiceError } from "./http-service-error";
import { Service } from "./service";
import { TokenManager } from "./token-manager";

/**
 * Combines a async function to make a get request
 * along with the metadata required to plug that request
 * into an admin ui
 */
interface GetFn<O> {
  description(): string;
  rtype: HttpGet<O>;
  call(): Promise<O>;
}

/**
 * Combines a async function to make a post request
 * along with the metadata required to plug that request
 * into an admin ui
 */
interface PostFn<I, O> {
  description(): string;
  rtype: HttpPost<I, O>;
  call(req: I): Promise<O>;
}


/**
 * The scoring backend service.
 */
export class HttpService implements Service {
  postLogin: PostFn<R.LoginReq, R.LoginResp>;
  getWhoAmI: GetFn<R.UserProfile>;
  getSeasons: GetFn<R.SeasonDetails[]>;
  postRaceSchedule: PostFn<DB.SeasonId, R.RaceScheduleResp>;
  postGetRaceEntrants: PostFn<DB.RaceId, R.RaceEntrant[]>;
  postGetRaceResults: PostFn<DB.RaceId, R.GetRaceResultsResp>;
  postUpdateRaceResults: PostFn<R.UpdateRaceResultsReq, Unit>;
  postGetSeriesResults: PostFn<DB.SeriesId, R.GetSeriesResultsResp>;
  postGetEventResults: PostFn<DB.EventId, R.GetEventResultsResp>;
  postGetSeasonEventResults: PostFn<DB.SeasonId, R.EventResults[]>;

  postCreateUser: PostFn<R.UserReq, DbKey<DB.AppUser>>;
  postUpdateUser: PostFn<WithDbId<R.UserReq>, Unit>;
  postDeleteUser: PostFn<DbKey<DB.AppUser>, Unit>;
  postQueryUsers: PostFn<TableQuery, Paginated<WithDbId<DB.AppUser>>>;
  postSetUserPassword: PostFn<R.SetPasswordReq, R.SetPasswordResp>;

  postAdminQueryTables: PostFn<TableQuery, Paginated<AR.Table>>;
  postAdminQueryDecls: PostFn<TableQuery, Paginated<MetaAdlDecl>>;
  postAdminCreate: PostFn<AR.CreateReq, AR.DbResult<DbKey<AR.DbRow>>>;
  postAdminQuery: PostFn<AR.QueryReq, Paginated<WithDbId<AR.DbRow>>>;
  postAdminUpdate: PostFn<AR.UpdateReq, AR.DbResult<Unit>>;
  postAdminDelete: PostFn<AR.DeleteReq, AR.DbResult<Unit>>;
  postAdminDbKeyLabels: PostFn<AR.DbKeyLabelReq[], AR.DbKeyLabelResp[]>;

  constructor(
    /** Fetcher over HTTP */
    private readonly http: HttpFetch,
    /** Base URL of the API endpoints */
    private readonly baseUrl: string,
    /** Resolver for ADL types */
    private readonly resolver: DeclResolver,
    /** Token manager storing the authentication tokens */
    private readonly tokenManager: TokenManager,
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    private readonly handleError: (error: HttpServiceError) => void
  ) {

    const api = annotatedApi(this.resolver, R.snApiRequests, R.makeApiRequests({}));
    this.postLogin = this.mkPostFn(api.login);
    this.getWhoAmI = this.mkGetFn(api.whoAmI);
    this.getSeasons = this.mkGetFn(api.seasons);
    this.postRaceSchedule = this.mkPostFn(api.raceSchedule);
    this.postGetRaceEntrants = this.mkPostFn(api.getRaceEntrants);
    this.postGetRaceResults = this.mkPostFn(api.getRaceResults);
    this.postUpdateRaceResults = this.mkPostFn(api.updateRaceResults);
    this.postGetSeriesResults = this.mkPostFn(api.getSeriesResults);
    this.postGetEventResults = this.mkPostFn(api.getEventResults);
    this.postGetSeasonEventResults = this.mkPostFn(api.getSeasonEventResults);

    this.postCreateUser = this.mkPostFn(api.createUser);
    this.postUpdateUser = this.mkPostFn(api.updateUser);
    this.postDeleteUser = this.mkPostFn(api.deleteUser);
    this.postQueryUsers = this.mkPostFn(api.queryUsers);
    this.postSetUserPassword = this.mkPostFn(api.setUserPassword);

    const adminApi = annotatedApi(this.resolver, AR.snAdminApiRequests, AR.makeAdminApiRequests({}));
    this.postAdminQueryTables = this.mkPostFn(adminApi.queryTables);
    this.postAdminQueryDecls = this.mkPostFn(adminApi.queryDecls);
    this.postAdminCreate = this.mkPostFn(adminApi.create);
    this.postAdminQuery = this.mkPostFn(adminApi.query);
    this.postAdminUpdate = this.mkPostFn(adminApi.update);
    this.postAdminDelete = this.mkPostFn(adminApi.delete);
    this.postAdminDbKeyLabels = this.mkPostFn(adminApi.dbKeyLabels);
  }

  async login(req: R.LoginReq): Promise<R.LoginResp> {
    return this.postLogin.call(req);
  }

  async whoami(): Promise<R.UserProfile> {
    return this.getWhoAmI.call();
  }

  seasons = ():Promise<R.SeasonDetails[]> => {
    return this.getSeasons.call();
  }

  raceSchedule = (req: DB.SeasonId):Promise<R.RaceScheduleResp> => {
    return this.postRaceSchedule.call(req);
  }

  getRaceEntrants = (req: DB.RaceId): Promise<R.RaceEntrant[]> => {
    return this.postGetRaceEntrants.call(req);
  }


  getRaceResults = (req: DB.RaceId): Promise<R.GetRaceResultsResp> => {
    return this.postGetRaceResults.call(req);
  }

  updateRaceResults = (req: R.UpdateRaceResultsReq): Promise<Unit> => {
    return this.postUpdateRaceResults.call(req);
  }

  getSeriesResults = (req: DB.SeriesId): Promise<R.GetSeriesResultsResp> => {
    return this.postGetSeriesResults.call(req);
  }

  getEventResults = (req: DB.EventId): Promise<R.GetEventResultsResp> => {
    return this.postGetEventResults.call(req);
  }

  getSeasonEventResults = (req: DB.SeasonId): Promise<R.EventResults[]> => {
    return this.postGetSeasonEventResults.call(req);
  }


  async createUser(req: R.UserReq): Promise<DbKey<DB.AppUser>> {
    return this.postCreateUser.call(req);
  }

  async updateUser(req: WithDbId<R.UserReq>): Promise<void> {
    await this.postUpdateUser.call(req);
  }

  async deleteUser(req: DbKey<R.UserReq>): Promise<void> {
    await this.postDeleteUser.call(req);
  }

  async queryUsers(req: TableQuery): Promise<Paginated<WithDbId<DB.AppUser>>> {
    return this.postQueryUsers.call(req);
  }

  async setUserPassword(req: R.SetPasswordReq): Promise<R.SetPasswordResp> {
    return this.postSetUserPassword.call(req);
  }

  async adminQueryTables(req: TableQuery): Promise<Paginated<AR.Table>> {
    return this.postAdminQueryTables.call(req);
  }

  async adminQueryDecls(req: TableQuery): Promise<Paginated<MetaAdlDecl>> {
    return this.postAdminQueryDecls.call(req);
  }

  async adminCreate(req: AR.CreateReq): Promise<AR.DbResult<DbKey<AR.DbRow>>> {
    return this.postAdminCreate.call(req);
  }

  async adminQuery(req: AR.QueryReq): Promise<Paginated<WithDbId<AR.DbRow>>> {
    return this.postAdminQuery.call(req);
  }

  async adminUpdate(req: AR.UpdateReq): Promise<AR.DbResult<Unit>> {
    return this.postAdminUpdate.call(req);
  }

  async adminDelete(req: AR.DeleteReq): Promise<AR.DbResult<Unit>> {
    return this.postAdminDelete.call(req);
  }

  async adminDbKeyLabels(req: AR.DbKeyLabelReq[]): Promise<AR.DbKeyLabelResp[]> {
    return this.postAdminDbKeyLabels.call(req);
  }

  private mkGetFn<O>(req: AnnotatedReq<HttpGet<O>>): GetFn<O> {
    const jb = createJsonBinding(this.resolver, req.rtype.respType);
    return {
      description: () => req.description,
      rtype: req.rtype,
      call: () => {
        return this.getAdl(req.rtype.path, jb, req.actionName);
      }
    };
  }

  private mkPostFn<I, O>(req: AnnotatedReq<HttpPost<I, O>>): PostFn<I, O> {
    const bb = createBiBinding<I, O>(this.resolver, req.rtype);
    return {
      description: () => req.description,
      rtype: req.rtype,
      call: (ival: I) => {
        return this.postAdl(req.rtype.path, bb, ival, req.actionName);
      }
    };
  }

  private async getAdl<O>(
    path: string,
    respJB: JsonBinding<O>,
    actionName: string
  ): Promise<O> {
    return this.requestAdl("get", path, null, respJB, actionName);
  }

  private async postAdl<I, O>(
    path: string,
    post: BiBinding<I, O>,
    req: I,
    actionName: string
  ): Promise<O> {
    const jsonArgs = post.reqJB.toJson(req);
    return this.requestAdl("post", path, jsonArgs, post.respJB, actionName);
  }

  private async requestAdl<O>(
    method: "get" | "post",
    path: string,
    jsonArgs: {} | null,
    respJB: JsonBinding<O>,
    /** Publicly consumable action of the request for error alerting purposes */
    actionName: string
  ): Promise<O> {
    // Construct request
    const authToken = this.tokenManager.getToken();
    const headers: { [key: string]: string } = {};
    headers["Content-Type"] = "application/json";
    if (authToken) {
      headers["X-Auth-Token"] = authToken;
    }
    const httpReq: HttpRequest = {
      url: this.baseUrl + path,
      headers,
      method,
      body: jsonArgs ? JSON.stringify(jsonArgs) : undefined
    };

    // Make request
    const resp = await this.http.fetch(httpReq);

    // Check for errors
    if (!resp.ok) {
      const bodyText = await resp.text();
      let publicMessageFragment = "";
      try {
        const bodyJson = JSON.parse(bodyText);
        if (bodyJson.publicMessage) {
          publicMessageFragment = `: ${bodyJson.publicMessage}`;
        }
      } catch (e) {
        // Not JSON
      }

      const error = new HttpServiceError(
        `Encountered server error attempting to call ${actionName} ${publicMessageFragment}`,
        `${httpReq.method} request to ${httpReq.url} failed: ${
          resp.statusText
        } (${resp.status}): ${bodyText}`,
        resp.status
      );
      this.handleError(error);
      throw error;
    }

    // Parse response
    try {
      const respJson = await resp.json();
      return respJB.fromJson(respJson);
    } catch (e) {
      const error = new HttpServiceError(
        "Encountered parse error attempting to call " + actionName,
        e.getMessage(),
        resp.status
      );
      this.handleError(error);
      throw error;
    }
  }
}

interface BiTypeExpr<I, O> {
  reqType: ATypeExpr<I>;
  respType: ATypeExpr<O>;
}

interface BiBinding<I, O> {
  reqJB: JsonBinding<I>;
  respJB: JsonBinding<O>;
}

function createBiBinding<I, O>(
  resolver: DeclResolver,
  rtype: BiTypeExpr<I, O>
): BiBinding<I, O> {
  return {
    reqJB: createJsonBinding(resolver, rtype.reqType),
    respJB: createJsonBinding(resolver, rtype.respType)
  };
}

const texprDocString: ATypeExpr<string> = {
  value: {
    typeRef: {
      kind: "reference",
      value: { moduleName: "sys.annotations", name: "Doc" }
    },
    parameters: []
  }
};


interface AnnotatedReq<RT> {
  actionName: string; 
  description: string;
  rtype: RT;
}

type AnnotatedApi<A> = { 
  [RT in keyof A]: AnnotatedReq<A[RT]>
}

/**
 * Merge the information available as API annotations into the API value
 */
function annotatedApi<API>(resolver: DeclResolver, apisn: adlast.ScopedName, api: API): AnnotatedApi<API> {
  const apiDecl = resolver(apisn);
  if (apiDecl.decl.type_.kind !== "struct_") {
    throw new Error("BUG: api is not a struct");
  }
  const apiStruct = apiDecl.decl.type_.value;

  //tslint:disable:no-object-literal-type-assertion
  const result = {} as AnnotatedApi<API>;

  for (const k of Object.keys(api)) {
      const rtype =  api[k];
      const apiField = apiStruct.fields.find(f => f.name === k);
      if (!apiField) {
        throw new Error("BUG: api  missing a field");
      }
      const description = getAnnotation(
        createJsonBinding(resolver, texprDocString),
        apiField.annotations
      ) || "";
      result[k] = {
        actionName: apiField.name,
        description,
        rtype,
      };
  }
  return result;
}
