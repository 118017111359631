import React, {} from "react";

const styles = require("./table.css");

export function stdTable( header: (JSX.Element| string)[], rows: (JSX.Element| string)[][] ): JSX.Element {
    return (
      <table className={styles.stdtable}>
        <thead>
          <tr className={styles.stdtablelabelrow}>
            {header.map((v,i) => <td key={i} className={styles.stdtablelabel}>{v}</td> )}
          </tr>
        </thead>
        <tbody className={styles.stdtablebody}>
          {rows.map( (row,i) => 
            <tr key={i} className={styles.stdtablerow}>
            {row.map((v,j) => <td key={j} className={styles.stdtablecontent}>{v}</td> )}
            </tr>
          )}
        </tbody>
      </table>
    );
  }

export function structTable( rows0: {label: string, content: JSX.Element| string}[] ): JSX.Element {
    const rows = rows0.map( (row,i) => 
      <tr key={i} className={styles.structtablerow}>
        <td key={0} className={styles.structtablelabel}>{row.label}</td>
        <td key={1} className={styles.structtablecontent}>{row.content}</td>
      </tr>
    );
    return (
      <table className={styles.structtable}>
        <tbody className={styles.structtablebody}>
          {rows}
        </tbody>
      </table>
    );
  }