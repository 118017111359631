/** Returns the file extension of the provided string */
export function fileExtension(s: string): string {
  const extension = s.split(".").pop();
  if (extension === undefined) {
    throw new Error("Not able to identify file extension of: " + s);
  }
  return "." + extension;
}

// /** Helper function to check if a is a subset of b */
// export function isSubset<T>(a: T[], b: T[]): boolean {
//   return a.every(val => b.includes(val));
// }


// /** Helper function to be used in a reduce to return the unique elements of an array */
// export function unique<T>(ts: T[], item: T): T[] {
//   return ts.includes(item) ? ts : [...ts, item]
// }


export class VSet<T> {

  private values_: {[key:string]: T} = {};
  size: number = 0;

  constructor(readonly keyfn: (t:T) => string) {
  }

  add(t: T) {
    const k = this.keyfn(t);
    if (k in this.values_) {
      return;
    }
    this.values_[k] = t;
    this.size += 1;
  }

  addAll(ts: T[]) {
    ts.forEach(t => this.add(t));
  }

  values(): T[] {
    const result: T[] = [];
    for(const k of Object.keys(this.values_)) {
      result.push(this.values_[k]);
    }
    return result;
  }
}


export class VMap<K,V> {

  private values_: {[key:string]: VMapEntry<K,V>} = {};

  constructor(readonly keyfn: (t:K) => string) {
  }

  put(key: K, value:V) {
    this.values_[this.keyfn(key)] = {key, value};
  }

  get(key:K): V | undefined {
    const entry = this.values_[this.keyfn(key)];
    return entry == undefined ? entry : entry.value;
  }

  entries(): VMapEntry<K,V>[] {
    const entries: VMapEntry<K,V>[] = [];
    for(const kstr of Object.keys(this.values_)) {
      entries.push(this.values_[kstr]);
    }
    return entries;
  }
};

export interface VMapEntry<K,V> {
  key: K,
  value: V
};