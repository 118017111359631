import * as React from 'react';
import { Route, pathFromRoute } from "../../routing/app-routes";


export interface RouteLinkProps {
  text: string,
  route: Route,
  onNavigate(to: Route): void;
}
    
/**
 * The Main page.
 */
export function RouteLink(props: RouteLinkProps) {
  function onClick(ev: any) {
    props.onNavigate(props.route);
    ev.preventDefault();
    return false;
  }

  return <a href={pathFromRoute(props.route)} onClick={onClick}>{props.text}</a>;
}