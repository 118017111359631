import { UpdateFn, VEditor } from "@adltools/adl-veditor";
import { AdlField } from "@adltools/components/AdlField";
import { FieldFns, regexStringFieldFns } from "@hx/fields";
import * as React from "react";
import { Icon } from "semantic-ui-react";

import * as styles from "./dbkey.css";
import { HrefFactory } from "./utils";

interface DbKeyVEditorProps {
  tableName: string;
  hrefFactory: HrefFactory;
}

export function dbKeyFieldFns(tableName: string): FieldFns<string> {
  return regexStringFieldFns("^\\S+$", `an existing ${tableName} id`);
}

export function dbKeyVEditor(props: DbKeyVEditorProps) {
  const fieldfns = dbKeyFieldFns(props.tableName);
  return fieldVEditor(props, fieldfns);
}

type FieldState = string;
type FieldEvent = string;

export function fieldVEditor<T>(
  props: DbKeyVEditorProps,
  fieldfns: FieldFns<T>
): VEditor<T, FieldState, FieldEvent> {
  return {
    initialState: "",
    stateFromValue: v => fieldfns.toText(v),
    validate: s => listFromNull(fieldfns.validate(s)),
    valueFromState: s => fieldfns.fromText(s),
    update: (_state: FieldState, event: FieldEvent) => event,
    render: (state: FieldEvent, canEdit: boolean, onUpdate: UpdateFn<FieldEvent>) => {
      if (canEdit) {
        return (
          <span className={styles.fieldContainer}>
            <AdlField fieldfns={fieldfns} text={state} disabled={false} onChange={onUpdate} />
            <span className={styles.linkIcon}>
              <a href={props.hrefFactory.table(props.tableName)} target="_blank">
                <Icon name="external alternate" />
              </a>
            </span>
          </span>
        );
      } else {
        return <a className={styles.idlink} href={props.hrefFactory.value(props.tableName, state)}>{state}</a>;
      }
    }
  };
}

function listFromNull<T>(v: T | null): T[] {
  if (v === null) {
    return [];
  } else {
    return [v];
  }
}
