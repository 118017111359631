import React, { useEffect, useState } from "react";
import * as API from "src/adl-gen/myc/scoring/api";
import { RESULT_DURATION_FIELDFNS, RESULT_LOCALTIME_FIELDFNS } from "src/ui/fieldfns";

import { EventId, EventResult, RaceType } from "../../../adl-gen/myc/scoring/db";
import { Loading } from "../../../models/loading2";
import { stdTable, structTable } from "../../../ui/common/table/table";
import { renderLoading } from "../../../ui/widgets/loader/loader2";

const styles = require("./event-results-page.css");

/** Props for the component */
export interface EventResultsProps {
  eventId: EventId,
  getEventResults(eventId: EventId): Promise<API.GetEventResultsResp>;
}

/**
 * The Event Results page.
 */
export const EventResultsPage = (props:EventResultsProps) => {

  const [eventResults, setEventResults] = useState<Loading<API.EventResults | null>>({kind:"loading"});

  useEffect(() => {
    async function load() {
      setEventResults({kind:"loading"});
      const results = await props.getEventResults(props.eventId);
      setEventResults({kind:"value", value: results});
    }
    void load()
  }, [props.eventId]);

  const renderResultTables = (event: API.EventResults) => {
    const headertable = structTable([
      {label: "Date", content: event.date},
      {label: "Conditions", content: event.conditions},
      {label: "Start Type", content: RaceType[event.raceType]},
      {label: "Start Time", content: event.startTime},
    ]);

    const isHandicap = event.isHandicap;
    const isPursuit  = event.raceType == RaceType.pursuit;
    const columns: {
      visible: boolean,
      label: string,
      rfn(r:EventResult): string;
    }[] = [
      {visible: true, label:"", rfn: r => r.elapsedTime.kind == 'finished' ? r.score.toString(): ""},
      {visible: true, label:"Entrant", rfn: r => r.entrantName},
      {visible: true, label:"Boat", rfn: r => r.boatName},
      {visible: true, label:"Sail", rfn: r => r.sailNumber},
      {visible: !isHandicap, label:"Yardstick", rfn: r => r.yardstick.toString()},
      {visible: isHandicap || isPursuit, label:"Handicap", rfn: r =>  r.handicap ? r.handicap.toFixed(2) : ""},
      {visible: isPursuit, label:"Pursuit Secs", rfn: r =>  r.handicapSecs != null ? r.handicapSecs.toString() : ""},
      {visible: true, label:"Finish", rfn: r => RESULT_LOCALTIME_FIELDFNS.toText(r.finishTime)},
      {visible: true, label:"Elapsed", rfn: r => RESULT_DURATION_FIELDFNS.toText(r.elapsedTime)},
      {visible: !isHandicap, label:"Elapsed CY", rfn: r => RESULT_DURATION_FIELDFNS.toText(r.elapsedTimeYardstick)},
      {visible: isHandicap && !isPursuit, label:"Elapsed CH", rfn: r => r.elapsedTimeHandicap ? RESULT_DURATION_FIELDFNS.toText(r.elapsedTimeHandicap) : ""},
      {visible: true, label:"Score", rfn: r => r.score.toString()},
      {visible: isHandicap, label:"Handicap +/-", rfn: r => r.handicapChange ? r.handicapChange.toFixed(2) : ""},
      {visible: isHandicap, label:"Handicap New", rfn: r => r.handicapNew ? r.handicapNew.toFixed(2) : ""},
    ];

    const vcolumns = columns.filter(c => c.visible);
    const header = vcolumns.map(c => c.label);
    const rowfn = (row:EventResult) => {
      return vcolumns.map(c => c.rfn(row));
    }
    const resultstable = stdTable(header,event.results.map(rowfn));

    const details = event.abandoned 
       ? <div className={styles.resultstablecontainer}>Race Abandoned</div>
       : <div className={styles.resultstablecontainer}>
           {resultstable}
        </div>
       ;

    return (
      <div className="page-layout">
        <div className="container">
           <h1>{event.abbreviation} Results</h1>
           <div className={styles.headertablecontainer}>
             {headertable}
          </div>
          {details}
        </div>
     </div>
     );
  }

  const renderResults = (results: API.EventResults | null) => {
    if (results === null) {
      return <div>No results found</div>;
    } else {
      return renderResultTables(results);
    }
  }

  return renderLoading(eventResults, renderResults);
};
