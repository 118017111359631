import { localTimeFieldFns, stringFieldFns, labelledValuesFieldFns } from "@hx/fields";
import React, { useEffect, useState } from "react";

import { Unit } from "../../../adl-gen/common";
import { GetRaceResultsResp, UpdateRaceResultsReq, RaceEntrant, EntrantRaceResult } from "../../../adl-gen/myc/scoring/api";
import { RaceId } from "../../../adl-gen/myc/scoring/db";
import { createField, createImmutableFieldState, Field, ImmutableFieldState, useField } from "../../widgets/entry-field/entry-field";
import { stdTable, structTable } from "../../common/table/table";
import { DATE_FIELD_FNS, RESULT_LOCALTIME_FIELDFNS } from "../../../ui/fieldfns";

const styles = require("./race-results-editor-page.css");

/** Props for the component */
export interface RaceResultsEditorProps {
  raceId: RaceId;
  getRaceEntrants(raceId: RaceId): Promise<RaceEntrant[]>;
  getRaceResults(raceId: RaceId): Promise<GetRaceResultsResp>;
  updateRaceResults(req: UpdateRaceResultsReq): Promise<Unit>;
}


export const RaceResultsEditorPage = (props:RaceResultsEditorProps) => {
  const scheduledDate = useField(DATE_FIELD_FNS);
  const actualDate = useField(DATE_FIELD_FNS);
  const startTime = useField(localTimeFieldFns);
  const conditions = useField(stringFieldFns);
  const notes = useField({
    ...stringFieldFns,
    rows: 4,
    width: 40,
  });

  const abandoned = useField(labelledValuesFieldFns("yes/no", (v1,v2) => v1 == v2, [
    {value : true, label: "yes"},
    {value : false, label: "no"},
  ]))

  const [events, setEvents] = useState<string[]>([]);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [resultRows, setResultRows] = useState<ResultRow[]>([]);

  async function load() {
    const entrants: {[id: string]:RaceEntrant} = {};
    (await props.getRaceEntrants(props.raceId)).forEach(e => {
      entrants[e.entrant.id]= e;
    });
    const loadedResults = await props.getRaceResults(props.raceId);
    loadedResults.results.sort( (er1, er2) =>cmpHandicap(entrants, er1, er2) )
    scheduledDate.setValue(loadedResults.scheduledDate);
    setEvents(loadedResults.events.map( ev => ev.value.abbreviation));
    if (loadedResults.startDetails) {
      actualDate.setValue(loadedResults.startDetails.date);
      startTime.setValue(loadedResults.startDetails.startTime);
      conditions.setValue(loadedResults.startDetails.conditions);
      notes.setValue(loadedResults.startDetails.notes);
      abandoned.setValue(loadedResults.startDetails.abandoned);
    } else {
      actualDate.setValue(loadedResults.scheduledDate);
      abandoned.setValue(false);
    }
    const rows: ResultRow[] = loadedResults.results.map( (r,i) => {
      const initial = RESULT_LOCALTIME_FIELDFNS.toText(r.result);
      return {
        entrantId: r.entrantId,
        entrantName: entrants[r.entrantId].entrant.value.entrantName,
        boatName: entrants[r.entrantId].entrant.value.boatName,
        sailNumber: entrants[r.entrantId].entrant.value.sailNumber,
        handicap: entrants[r.entrantId].handicap,
        resultState: createImmutableFieldState(initial, fs => {
          setResultRows( existingRows => {
            const newRows = existingRows.slice();
            newRows[i] = {...existingRows[i], resultState: fs};
            return newRows;
          });
        }),
      };
    });
    setResultRows(rows);
    setSaveDisabled(false);
  }

  useEffect(() => {
    void load();
  }, [props.raceId]);


  const allFields: Field<unknown>[] = [scheduledDate, actualDate, startTime, conditions, notes, abandoned];
  resultRows.forEach( rr => allFields.push(createField(RESULT_LOCALTIME_FIELDFNS, rr.resultState)))
  const formValid = allFields.every( f => f.isValid());
  const formModified = !allFields.every( f => !f.isModified());

  const headertable = structTable([
    {label: "Scheduled Date", content: scheduledDate.text},
    {label: "Events", content: events.join(', ')},
    {label: "Actual Date", content: actualDate.render()},
    {label: "Conditions", content: conditions.render()},
    {label: "Abandoned", content: abandoned.render() },
    {label: "Start Time", content: startTime.render()},
    {label: "Notes", content: notes.render()},
  ]);

  const resulttable = stdTable(
    ["Helm", "Boat", "Sail No", "Finish Time"],
    resultRows.map( rr => {
      const rfield = createField(RESULT_LOCALTIME_FIELDFNS, rr.resultState);
      return [rr.entrantName, rr.boatName, rr.sailNumber, rfield.render()];
    })
  );

  const updateResults = async () => {
    setSaveDisabled(true);
    await props.updateRaceResults({
      raceId: props.raceId,
      startDetails: {
        date: actualDate.value(),
        startTime: startTime.value(),
        conditions: conditions.value(),
        notes: notes.value(),
        abandoned: abandoned.value(),
      },
      results: resultRows.map( rr => {return {
        entrantId: rr.entrantId,
        result: createField(RESULT_LOCALTIME_FIELDFNS, rr.resultState).value()
      }})
    });
    await load();
  }

  const revertChanges = () => {
    allFields.forEach( f => f.revert() );
  }

  return (
    <div className="page-layout">
      <div className="container">
        <h1>Race Results</h1>
        <div className={styles.headercontent}>{headertable}</div>
        <div className={styles.resultscontent}>{resulttable}</div>
        <div>
        <button className={styles.revertbutton} disabled={saveDisabled || !formValid || !formModified} onClick={revertChanges}>
          Revert
        </button>
        <button className={styles.savebutton} disabled={!formModified} onClick={updateResults}>
          Save Changes
        </button>
        </div>
      </div>
    </div>
    );
}

interface ResultRow {
  entrantId: string;
  entrantName: string,
  boatName: string,
  sailNumber: string,
  resultState: ImmutableFieldState;
  handicap: number;
};

function cmpHandicap(entrants: {[id: string]:RaceEntrant}, er1: EntrantRaceResult, er2: EntrantRaceResult) {
  const e1 = entrants[er1.entrantId];
  const e2 = entrants[er2.entrantId];
  const h1 = e1 == null ? 0 : e1.handicap;
  const h2 = e2 == null ? 0 : e2.handicap;
  return h1 < h2 ? 1 : h1 > h2 ? -1 : 0;
}