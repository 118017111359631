import { action, observable } from "mobx";

import { LoginReq, UserProfile } from "../adl-gen/myc/scoring/api";
import { AdminStore } from "../adminui/store";
import { pathFromRoute, Route, routeFromPath } from "../routing/app-routes";
import { Router } from "../routing/router";
import { Service } from "../service/service";

import { IdentityStore } from "./identity-store";

/** Top level app state */
export type AppState =
  | { kind: "login" }
  | { kind: "logout" }
  | { kind: "secure", userProfile: UserProfile, route: Route }
  | { kind: "public", userProfile: UserProfile | undefined, route: Route }
  | { kind: "admin"; userProfile: UserProfile; store: AdminStore }

  | {
      kind: "admin-table";
      userProfile: UserProfile;
      table: string;
      store: AdminStore;
    };


/**
 * Top level app data and actions, including navigation.
 */
export class AppStore {
  @observable.deep state: AppState;

  constructor(
    /** Router which is the bridge to the browser location */
    private readonly router: Router,
    /** The server backend */
    readonly service: Service,
    /** The global identity store */
    readonly identityStore: IdentityStore,
    /** Instantiates an admin store */
    private readonly makeAdminStore: (table?: string) => AdminStore,
  ) {
    this.state = { kind: "login" };

    // Asynchronously register route change listener to not block construction,
    // in case the store factories rely on the app store being constructed
    setTimeout(() => router.registerRouteChangeListener(this.onRouteChange), 0);
  }

  getRoute(): Route  | undefined {
    return routeFromPath(this.router.getCurrentPath());
  }

  /** Updates state based on route changes */
  @action onRouteChange = async () => {
    const userProfile = await this.identityStore.userProfilePromise;
    const isAdminUser = userProfile && userProfile.isAdmin;
    let route = this.getRoute();

    if (route === undefined) {
      // Redirect invalid routes to main
      void this.navigateTo({ route: "main" });
      return;
    }

    if (route.route === "login") {
      this.state = { kind: "login"};
      return;
    }

    if (route.route === "logout") {
      this.identityStore.onLogout();
      this.state = { kind: "logout" };
      return;
    }

    if (route.route === "admin" && userProfile && isAdminUser) {
      this.state = {
        kind: "admin",
        store: this.getAdminStore(),
        userProfile
      };
      return;
    }

    if (route.route === "admin-table" && userProfile && isAdminUser) {
      this.state = {
        kind: "admin-table",
        table: route.table,
        store: this.getAdminStore(route.table),
        userProfile
      };
      return;
    }

    if (route.route === "race-results" && userProfile) {
      this.state = {
        kind: "secure",
        route,
        userProfile
      };
      return;
    }

    this.state = {
        kind: "public",
        userProfile,
        route,
    };
    return;
  };

  getAdminStore = (table?: string): AdminStore => {
    if (this.state.kind === "admin" || this.state.kind === "admin-table") {
      if (this.state.store.tableName === table) {
        return this.state.store;
      }
    }
    return this.makeAdminStore(table);
  };

  /** Logs user in and redirects to main page */
  @action
  onLogin = async (req: LoginReq) => {
    const loginState = await this.identityStore.onLogin(req);
    // tslint:disable-next-line: no-console
    console.log("loginState", loginState);
    if (loginState.kind === 'logged-in') {
      void this.navigateTo({ route: "main" });
    }
  };

  /** Logs user out and redirects to logout page */
  @action
  onLogout = () => {
    this.identityStore.onLogout();
    void this.navigateTo({ route: "logout" });
  };

  navigateTo = (route: Route) => {
    return this.router.go(pathFromRoute(route));
  }
}
