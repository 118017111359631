import { observer } from "mobx-react";
import * as React from "react";

import { LoginReq } from "../../../adl-gen/myc/scoring/api";
import {
  FieldState,
  newNonEmptyTextField
} from "../../widgets/text-input/text-field";
import { TextInput } from "../../widgets/text-input/text-input";

const styles = require("./login-page.css");

export interface State {
  /** Error message to show upon login failure */
  loginError?: string;
};

export type Event =
  /** Callback to login */
 | { kind: 'onLogin', req: LoginReq }
  ;

export interface LoginPageProps {
  state: State;
  onEvent(event: Event): Promise<void>;
}

/**
 * Login page of the app.
 */
@observer
export class LoginPage extends React.Component<LoginPageProps> {
  email: FieldState<string> = newNonEmptyTextField();
  password: FieldState<string> = newNonEmptyTextField();

  /** Renders the page */
  render() {
    return (
      <div>
        <TextInput type="text" state={this.email} placeholder="Email" />
        <TextInput
          type="password"
          state={this.password}
          placeholder="Password"
        />
        <p className={styles.error}>{this.props.state.loginError}</p>
        <button onClick={this.onLogin} className={styles.button}>
          Login
        </button>
      </div>
    );
  }

  /** Returns the validated login form */
  getValidLoginForm(): LoginReq | undefined {
    const email = this.email.getValid();
    const password = this.password.getValid();

    if (email && password) {
      return {
        email,
        password
      };
    } else {
      return undefined;
    }
  }

  /** Callback to log in */
  onLogin = async () => {
    const data = this.getValidLoginForm();
    if (data) {
      return this.props.onEvent({ kind: 'onLogin', req: data });
    }
  };
}
