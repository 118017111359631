import { RaceId, SeasonId, SeriesId, EventId } from "../adl-gen/myc/scoring/db";
import { HrefFactory } from "../adminui/utils";

/** A typesafe route */

export type Route =
  | { route: "main" }
  | { route: "schedule", season: SeasonId}
  | { route: "race-signon", race: RaceId}
  | { route: "race-results", race: RaceId}
  | { route: "series-results", series: SeriesId}
  | { route: "event-results", event: EventId}
  | { route: "login" }
  | { route: "logout" }
  | { route: "admin" }
  | { route: "admin-table"; table: string }
  | { route: "admin-value"; table: string, id: string }

export function routeFromPath(path: string): Route | undefined {
  if (path.startsWith("/")) {
    const parts = path
      .substr(1)
      .split("/")
      .map(decodeURIComponent);
    if (parts.length === 1 && parts[0] === "main") {
      return { route: "main" };
    } else if (parts.length === 2 && parts[0] === "schedule") {
      return { route: "schedule", season: parts[1] };
    } else if (parts.length === 2 && parts[0] === "race-signon") {
      return { route: "race-signon", race: parts[1] };
    } else if (parts.length === 2 && parts[0] === "race-results") {
      return { route: "race-results", race: parts[1] };
    } else if (parts.length === 2 && parts[0] === "series-results") {
      return { route: "series-results", series: parts[1] };
    } else if (parts.length === 2 && parts[0] === "event-results") {
      return { route: "event-results", event: parts[1] };
    } else if (parts.length === 1 && parts[0] === "login") {
      return { route: "login" };
    } else if (parts.length === 1 && parts[0] === "logout") {
      return { route: "logout" };
    } else if (parts.length === 1 && parts[0] === "admin") {
      return { route: "admin" };
    } else if (parts.length === 2 && parts[0] === "admin-table") {
      return { route: "admin-table", table: parts[1] };
    } else if (parts.length === 3 && parts[0] === "admin-value") {
      return { route: "admin-value", table: parts[1], id: parts[2] };
    }
  }
  return undefined;
}

export function pathFromRoute(route: Route): string {
  switch (route.route) {
    case "main":
      return "/main";
    case "schedule":
      return "/schedule/" + route.season;
    case "race-signon":
      return "/race-signon/" + route.race;
    case "race-results":
      return "/race-results/" + route.race;
    case "series-results":
      return "/series-results/" + route.series;
    case "event-results":
      return "/event-results/" + route.event;
    case "login":
      return "/login";
    case "logout":
      return "/logout";
    case "admin":
      return "/admin";
    case "admin-table":
      return "/admin-table/" + route.table;
    case "admin-value":
      return "/admin-value/" + route.table + "/" + route.id;
  }
}



function hrefFactory() : HrefFactory {

  function table(tname: string): string {
    return pathFromRoute({ route: "admin-table", table:tname });
  }

  function value(tname: string, id: string): string {
    return pathFromRoute({ route: "admin-value", table:tname, id });
  
  }
  return {table, value};
}

export const ADMIN_HREF_FACTORY = hrefFactory();
