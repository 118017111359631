
import { sysCustomize } from "@adltools/adl-customize";
import { createVEditor, CustomContext, Customize, VEditor } from "@adltools/adl-veditor";
import { DDSMMSYYYY_FORMAT } from "@hx/dateformats";

import { DbRow, Table } from "../adl-gen/common/adminui/api";
import { snDbKey, WithDbId } from "../adl-gen/common/db";
import { makeTableQuery } from "../adl-gen/common/tabular";
import { ATypeExpr, DeclResolver } from "../adl-gen/runtime/adl";
import { createJsonBinding, JsonBinding } from "../adl-gen/runtime/json";
import { scopedNamesEqual } from "../adl-gen/runtime/utils";
import * as adlast from "../adl-gen/sys/adlast";

import { dbKeyFieldFns, dbKeyVEditor } from "./dbkey";
import { AdminService } from "./service";

// DbRow consists of ADL serialized values using the standard
// schema (https://github.com/timbod7/adl/blob/master/docs/serialization.md)
//
// The typescript ADL forms and table tooling is in terms of the typescript
// in-memory representation. We'll define a type for that for clarity.

export interface TSRow {
  [key: string]: unknown;
}

/**
 * Factory for building admin URLs
 */
export interface HrefFactory  {
    table(tablename: string): string;
    value(tablename: string, id: string): string;
}


/**
 *  Holds all metadata describing types accessible via the admin crud interface
 */
export interface Metadata {
    tableNames: string[];
    tableMap: { [name: string]: Table | undefined };
    resolver: DeclResolver;
  }


  export async function loadMetadata(service: AdminService, appDeclResolver: DeclResolver) : Promise<Metadata> {
    const tables = await service.adminQueryTables(makeTableQuery({}));
    const decls = await service.adminQueryDecls(makeTableQuery({}));
    const declMap: { [key: string]: adlast.ScopedDecl | undefined } = {};
    decls.items.forEach(mdecl => {
      const sdecl = { moduleName: mdecl.moduleName, decl: mdecl.decl };
      declMap[sdecl.moduleName + "." + sdecl.decl.name] = sdecl;
    });
  
    const tableMap: { [name: string]: Table } = {};
    tables.items.forEach(table => {
      tableMap[table.name] = table;
    });
  
    const mergedResolver = (sname: adlast.ScopedName): adlast.ScopedDecl => {
      const decl = declMap[sname.moduleName + "." + sname.name];
      if (decl !== undefined) {
        return decl;
      }
      return appDeclResolver(sname);
    };
  
    const metadata: Metadata = {
      tableNames: tables.items.map(t => t.name),
      tableMap,
      resolver: mergedResolver
    };
    return metadata;
  }

  
  /**
   * Utility type to store a value that may be loading
   */
  export type Loading<T> = { kind: "loading" } | { kind: "ready"; value: T };
  
  export function mapLoading<A, B>(fn: (a: A) => B, la: Loading<A>): Loading<B> {
    if (la.kind === "loading") {
      return la;
    }
    return { kind: "ready", value: fn(la.value) };
  }
  
  /**
   * Detailed metadata about the type stored in a specific DB Table
   */
  export interface TableMetadata {
    table: Table;
    resolver: DeclResolver;
    veditor: VEditor<TSRow, unknown, unknown>;
    jsonBinding: JsonBinding<TSRow>;
  
    tsRowFromDbRow(dbrow: DbRow): TSRow;
    dbRowFromTsRow(tsrow: TSRow): DbRow;
    customize: Customize;
  }
  
  export function createTableMetadata(
    metadata: Metadata,
    tableName: string,
    tableHrefFn: HrefFactory
  ): TableMetadata {
    const table = metadata.tableMap[tableName];
    if (table === undefined) {
      throw new Error("Unknown table " + tableName);
    }
    const tableScopedDecl = scopedDeclFromTable(table);
    const tableScopedName = {
      moduleName: tableScopedDecl.moduleName,
      name: tableScopedDecl.decl.name
    };
    const tableTypeExpr: ATypeExpr<TSRow> = {
      value: { typeRef: { kind: "reference", value: tableScopedName }, parameters: [] }
    };
    function resolver(scopedName: adlast.ScopedName): adlast.ScopedDecl {
      if (scopedNamesEqual(scopedName, tableScopedName)) {
        return tableScopedDecl;
      } else {
        return metadata.resolver(scopedName);
      }
    }
    const customize = appCustomize(metadata, tableHrefFn);
    const veditor: VEditor<TSRow, unknown, unknown> = createVEditor(
      tableTypeExpr,
      resolver,
      customize
    );
    const jsonBinding = createJsonBinding(resolver, tableTypeExpr);
    function tsRowFromDbRow(dbrow: DbRow): TSRow {
      return jsonBinding.fromJsonE(dbrow);
    }
    
    function dbRowFromTsRow(tsrow: TSRow): DbRow {
      return jsonBinding.toJson(tsrow) as DbRow;
    }
    return {
      table,
      resolver: metadata.resolver,
      veditor,
      jsonBinding,
      customize,
      tsRowFromDbRow,
      dbRowFromTsRow,
    };
  }  
  

export const SYS_CUSTOMIZE = sysCustomize({
    dateFormat: DDSMMSYYYY_FORMAT
  });
  
export function appCustomize(metadata: Metadata, hrefFactory: HrefFactory): Customize {
  return {
    getCustomVEditor: (ctx: CustomContext) => {
      const dbKeyTableName = getDbKeyTableName(ctx.typeExpr, metadata);
      if (dbKeyTableName !== null) {
        return dbKeyVEditor({
          tableName: dbKeyTableName,
          hrefFactory,
        });
      }
      return SYS_CUSTOMIZE.getCustomVEditor(ctx);
    },
    getCustomField: (ctx: CustomContext) => {
      const dbKeyTableName = getDbKeyTableName(ctx.typeExpr, metadata);
      if (dbKeyTableName !== null) {
        return dbKeyFieldFns(dbKeyTableName);
      }
      return SYS_CUSTOMIZE.getCustomField(ctx);
    }
  };
}

function scopedDeclFromTable(table: Table): adlast.ScopedDecl {
  const struct = adlast.makeStruct({
    typeParams: [],
    fields: table.columns.map(tcol => {
      return adlast.makeField({
        name: tcol.name,
        serializedName: tcol.name,
        typeExpr: tcol.typeExpr,
        default: tcol.defaultValue,
        annotations: []
      });
    })
  });
  const decl = adlast.makeDecl({
    name: table.declName,
    version: { kind: "nothing" },
    type_: { kind: "struct_", value: struct },
    annotations: []
  });
  return {
    moduleName: "__LOCAL__",
    decl
  };
}

/**
 * Return the associated table name if the typeExpression is DbKey<T>, otherwise
 * return null
 */
function getDbKeyTableName(typeExpr: adlast.TypeExpr, metadata: Metadata): string | null {
  let tableName: string | null = null;
  if (typeExpr.typeRef.kind === "reference" && scopedNamesEqual(typeExpr.typeRef.value, snDbKey)) {
    // Custom veditor for a db key
    if (typeExpr.parameters[0].typeRef.kind === "reference") {
      const sn = typeExpr.parameters[0].typeRef.value;
      for (const tn of metadata.tableNames) {
        const table = metadata.tableMap[tn];
        if (table && table.declModuleName === sn.moduleName && table.declName === sn.name) {
          tableName = table.name;
          break;
        }
      }
    }
  }
  return tableName;
}


export function dbKeyScopedName(typeExpr: adlast.TypeExpr) : adlast.ScopedName | undefined {
  if(typeExpr.typeRef.kind === 'reference' && scopedNamesEqual(typeExpr.typeRef.value, snDbKey) &&
      typeExpr.parameters[0].typeRef.kind === 'reference') {
    return typeExpr.parameters[0].typeRef.value;
  }

  return undefined;
}

/**
 * Map a function over the value of a WithDbId
 */
export function mapDbValue<A,B>(f: (a:A) => B, v: WithDbId<A>): WithDbId<B> {
  return {id:v.id, value: f(v.value)}
}
