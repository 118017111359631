import React, { useEffect, useState } from "react";
import { LocalDate } from "../../../adl-gen/common";
import { GetRaceResultsResp, RaceEntrant } from "../../../adl-gen/myc/scoring/api";
import { RaceId } from "../../../adl-gen/myc/scoring/db";
import { stdTable } from "../../common/table/table";
import { DATE_FIELD_FNS } from "../../fieldfns";

const styles = require("./race-signon-page.css");

/** Props for the component */
export interface RaceSigononProps {
  raceId: RaceId;
  getRaceEntrants(raceId: RaceId): Promise<RaceEntrant[]>;
  getRaceResults(raceId: RaceId): Promise<GetRaceResultsResp>;
}


export const RaceSignonPage = (props:RaceSigononProps) => {

  const [entrants, setEntrants] = useState<RaceEntrant[]>([]);
  const [scheduledDate, setScheduledDate] = useState<LocalDate | null>(null);



  async function load() {
    setScheduledDate(await (await props.getRaceResults(props.raceId)).scheduledDate);
    setEntrants(await props.getRaceEntrants(props.raceId));
  }

  useEffect(() => {
    void load();
  }, [props.raceId]);


  const signonHeader = [
    "Entrant",
    "Boat",
    "Sail No",
    "Handicap (CF)",
    "Handicap (min)",
    <div className={styles.signoncolumn}>Sign-on</div>,
    <div className={styles.notescolumn}>Notes</div>,
  ];
  const signonRows: (string|JSX.Element)[][] = entrants.map(e => {
    return [
      e.entrant.value.entrantName,
      e.entrant.value.boatName,
      e.entrant.value.sailNumber,
      e.handicap.toFixed(2),
      handicapMinutes(e.handicapSecs),
      "",
      "",
    ];
  });
  signonRows.sort((r1,r2) => r1[0] < r2[0] ? -1 : r1[0] > r2[0] ? 1 : 0);
  signonRows.push(["","","","","","",""]);
  signonRows.push([<b>Casual Entries</b>,"","","","","",""]);

  return (
    <div className="page-layout">
      <div className="container">
        <h1>Sign-on Sheet {scheduledDate ? DATE_FIELD_FNS.toText(scheduledDate) : ""}</h1>
        <p>Help the race officer - please check sail number etc and correct for the day if necessary!</p>
        <div className={styles.entranttablecontainer}>
        {stdTable(signonHeader, signonRows)}
        </div>
      </div>
    </div>
  );
}

function handicapMinutes(secs: number): string {
  return digits2(Math.floor(secs/60)) + ':' + digits2(Math.floor(secs % 60));
}

function digits2(n:number): string {
  return n < 10 ? ('0' + n.toString()) : n.toString();
}

